<template>
  <div class="roles-sections-item roles-sections-main">
    <h2 class="roles-sections-item__title">Общие сведения</h2>
    <label class="roles-sections-item__label">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">1. Название роли *</span>
        </template>
        <span>Название роли должно отражать ее суть.</span>
      </v-tooltip>
    </label>
    <v-text-field
    @input="updateTempRole"
    v-model.trim="role.name"
    label="Введите название роли"
    class="rounded-lg mb-10"
    hide-details
    :error="showNameError"
    background-color="white"
    outlined />

    <label class="roles-sections-item__label">2. Статус *</label>
    <v-radio-group class="roles-sections-item__radio mb-10" @change="updateTempRole" v-model="role.active" row hide-details>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-radio label="Активный" :value="true" v-bind="attrs" v-on="on"></v-radio>
        </template>
        <span>Активная роль дает доступ в назначенные модули.</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-radio label="Неактивный" :value="false" v-bind="attrs" v-on="on"></v-radio>
        </template>
        <span>Доступ по всем модулям будет запрещен.</span>
      </v-tooltip>
    </v-radio-group>

    <label class="roles-sections-item__label roles-sections-item__label--subbed">
      3. Сотрудники с этой ролью
      <span v-if="role.users.length > 0" class="btn-text" @click="clearAllusers">Удалить всех</span>
    </label>
    <v-combobox
      @change="updateTempRole"
      v-model="role.users"
      :items="managers.map(({ id, name }) => ({ id, name }))"
      item-text="name"
      item-value="id"
      chips
      label="Введите ФИО или выберите из списка"
      multiple
      hide-details
      background-color="white"
      class="rounded-lg roles-sections-item__combobox"
      outlined
      solo
    >
      <template v-slot:selection="{ attrs, item, select, selected }">
        <v-chip
          class="rounded-md roles-chip"
          v-bind="attrs"
          :input-value="selected"
          close
          @click="select"
          @click:close="removeMember(item)"
        >
          <strong>{{ item.name }}</strong>
        </v-chip>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';

export default {
  title: 'RoleCardMain',
  components: {},
  props: {
    showNameError: {
      type: Boolean,
      default: false,
    },
    roleEditData: {
      default: false,
    },
  },

  data() {
    return {
      role: {
        name: '',
        active: false,
        users: [],
      },
    };
  },

  created() {
    if (this.roleEditData && this.roleEditData.id) {
      this.role = JSON.parse(JSON.stringify(this.roleEditData));
      if (this.role.active) {
        delete this.role.permissions;
      }
    }
    this.updateTempRole();
  },

  async mounted() {
    await this.getManagers();
  },

  computed: {
    ...mapState('staff', ['managers']),
  },

  methods: {
    ...mapActions('staff', ['getManagers']),
    ...mapMutations('roles', ['setRoleTempMain']),

    updateTempRole() {
      this.role.users = this.role.users.filter((f) => typeof f === 'object');
      this.setRoleTempMain(this.role);
    },

    removeMember(member) {
      this.role.users = this.role.users.filter((f) => f.name !== member.name);
      this.updateTempRole();
    },
    clearAllusers() {
      this.role.users = [];
      this.updateTempRole();
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables.scss";

</style>
