<template>
  <div class="roles-sections-item roles-sections-permissions">
    <h2 class="roles-sections-item__title">Модули и права</h2>
    <div class="roles-sections-item-tabs">
      <div
        v-for="tab in permissions"
        :key="`tab-${tab.id}`"
        class="roles-sections-item-tabs__tab"
        :class="{ 'roles-sections-item-tabs__tab--active': currentTab.id === tab.id }"
        @click="setCurrentTab(tab)"
      >
        {{ tab.moduleName }}
      </div>
    </div>
    <div class="roles-sections-item-tree">
      <v-checkbox
        class="mb-3"
        v-model="selectAll"
        label="Выделить все"
        hide-details
        @change="selectAllPerms"
      />
      <div class="roles-sections-item-tree__item" v-for="perm in currentTab.modulePermissions" :key="`perm-${perm.id}`">
        <v-checkbox @change="checkSelectedPerms" v-model="role.permissions[perm.id]" :label="perm.label" hide-details />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'RoleCardPermissions',
  components: {},
  props: {
    roleEditData: {
      default: false,
    },
  },

  data() {
    return {
      role: {
        permissions: {},
      },
      selectAll: false,
      hasSelectedPermissions: false,
      currentTab: { id: 1 },
    };
  },

  computed: {
    ...mapState('roles', ['permissions', 'permissionsObject', 'roleTempPermissions']),
  },

  created() {
    this.currentTab = this.permissions.at(0);

    if (this.roleEditData && this.roleEditData.id) {
      this.role.permissions = this.roleEditData.permissions;
    } else {
      this.role.permissions = JSON.parse(JSON.stringify(this.permissionsObject));
    }
    this.updateTempRole();
  },

  methods: {
    ...mapMutations('roles', ['setRoleTempPermissions']),

    updateTempRole() {
      this.setRoleTempPermissions(this.role);
    },
    setCurrentTab(tab) {
      this.currentTab = tab;
      this.checkSelectedPerms();
    },
    selectAllPermissions(active) {
      this.currentTab.modulePermissions.forEach((f) => {
        this.role.permissions[f.id] = active;
      });

      if (active) {
        this.hasSelectedPermissions = true;
      } else {
        this.hasSelectedPermissions = false;
      }
    },
    checkSelectedPerms() {
      let hasSelected = false;
      let hasUnSelected = false;
      this.currentTab.modulePermissions.forEach((f) => {
        if (!hasSelected && this.role.permissions[f.id]) hasSelected = true;
        if (!hasUnSelected && !this.role.permissions[f.id]) {
          hasUnSelected = true;
          this.selectAll = false;
          this.hasSelectedPermissions = false;
        }
      });

      this.hasSelectedPermissions = hasSelected && hasUnSelected;
      if (!this.selectAll && hasSelected && !hasUnSelected) this.selectAll = true;
      this.updateTempRole();
    },
    selectAllPerms(val) {
      if (val) this.selectAllPermissions(true);
      if (!val) this.selectAllPermissions(false);
      this.updateTempRole();
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables.scss";

</style>
