var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"roles-sections-item roles-sections-main"},[_c('h2',{staticClass:"roles-sections-item__title"},[_vm._v("Общие сведения")]),_c('label',{staticClass:"roles-sections-item__label"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("1. Название роли *")])]}}])},[_c('span',[_vm._v("Название роли должно отражать ее суть.")])])],1),_c('v-text-field',{staticClass:"rounded-lg mb-10",attrs:{"label":"Введите название роли","hide-details":"","error":_vm.showNameError,"background-color":"white","outlined":""},on:{"input":_vm.updateTempRole},model:{value:(_vm.role.name),callback:function ($$v) {_vm.$set(_vm.role, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"role.name"}}),_c('label',{staticClass:"roles-sections-item__label"},[_vm._v("2. Статус *")]),_c('v-radio-group',{staticClass:"roles-sections-item__radio mb-10",attrs:{"row":"","hide-details":""},on:{"change":_vm.updateTempRole},model:{value:(_vm.role.active),callback:function ($$v) {_vm.$set(_vm.role, "active", $$v)},expression:"role.active"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-radio',_vm._g(_vm._b({attrs:{"label":"Активный","value":true}},'v-radio',attrs,false),on))]}}])},[_c('span',[_vm._v("Активная роль дает доступ в назначенные модули.")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-radio',_vm._g(_vm._b({attrs:{"label":"Неактивный","value":false}},'v-radio',attrs,false),on))]}}])},[_c('span',[_vm._v("Доступ по всем модулям будет запрещен.")])])],1),_c('label',{staticClass:"roles-sections-item__label roles-sections-item__label--subbed"},[_vm._v(" 3. Сотрудники с этой ролью "),(_vm.role.users.length > 0)?_c('span',{staticClass:"btn-text",on:{"click":_vm.clearAllusers}},[_vm._v("Удалить всех")]):_vm._e()]),_c('v-combobox',{staticClass:"rounded-lg roles-sections-item__combobox",attrs:{"items":_vm.managers.map(function (ref) {
	var id = ref.id;
	var name = ref.name;

	return ({ id: id, name: name });
}),"item-text":"name","item-value":"id","chips":"","label":"Введите ФИО или выберите из списка","multiple":"","hide-details":"","background-color":"white","outlined":"","solo":""},on:{"change":_vm.updateTempRole},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({staticClass:"rounded-md roles-chip",attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.removeMember(item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item.name))])])]}}]),model:{value:(_vm.role.users),callback:function ($$v) {_vm.$set(_vm.role, "users", $$v)},expression:"role.users"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }