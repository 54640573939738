<template>
  <div class="roles">
    <div class="roles-header">
      <div class="roles-header-title">
        <div
          class="roles-header-title__back"
          @click="$router.push('/roles')"
        >
          <v-icon color="#D6D6D6">mdi-chevron-left</v-icon>
        </div>
        <div class="roles-header-title__heading">Создание роли</div>
      </div>

      <div class="roles-header-add">
        <v-btn
          color="black"
          dark
          outlined
          class="radius-root mr-1"
          x-large
          @click="$router.push('/roles')"
        >
          Отменить
        </v-btn>
        <v-btn
          color="black"
          dark
          class="radius-root"
          x-large
          :disabled="!hasChanges"
          @click="save"
        >
          Сохранить
        </v-btn>
      </div>
    </div>
    <div class="roles-sections">
      <RoleCardMain :showNameError="showNameError" />
      <RoleCardPermissions />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import notification from '@/mixins/notification';
import RoleCardMain from './components/RoleCardMain.vue';
import RoleCardPermissions from './components/RoleCardPermissions.vue';

export default {
  name: 'RoleCreate',
  components: {
    RoleCardMain,
    RoleCardPermissions,
  },
  mixins: [notification],

  data() {
    return {
      hasChanges: false,
      showNameError: false,
    };
  },

  computed: {
    ...mapState('roles', ['roleTempMain', 'roleTempPermissions']),
  },

  methods: {
    ...mapActions('roles', ['createRole']),
    ...mapMutations('roles', ['setRoleTempMain', 'setRoleTempPermissions']),

    removeMember(member) {
      this.role.members = this.role.members.filter((f) => f.name !== member.name);
    },
    clearAllMembers() {
      this.role.members = [];
    },

    valid(data) {
      if (data.name === '' || data.length < 1) {
        this.showNameError = true;
        return false;
      }
      return true;
    },

    async save() {
      this.showNameError = false;
      const data = {
        ...this.roleTempMain,
        ...this.roleTempPermissions,
      };
      data.users = data.users.map((m) => m.id);

      if (this.valid(data)) {
        try {
          const res = await this.createRole(data);
          if (!this.validateStatusCode(res)) {
            if (res.data.name) {
              this.showErrorNotification('Роль с таким названием уже существует');
              this.showNameError = true;
            } else {
              throw new Error('error');
            }
          } else {
            this.showSuccessNotification(`Новая роль «${data.name}» успешно создана`);
            this.$router.push('/roles');
          }
        } catch (error) {
          this.showErrorNotification('Произошла ошибка попробуйте снова');
        }
      } else {
        this.showErrorNotification('Заполните название роли');
        this.showNameError = true;
      }
    },
  },

  watch: {
    roleTempMain: {
      deep: true,
      handler(n, o) {
        if (o) this.hasChanges = true;
      },
    },
    roleTempPermissions: {
      deep: true,
      handler(n, o) {
        if (o) this.hasChanges = true;
      },
    },
  },

  destroyed() {
    this.setRoleTempMain(false);
    this.setRoleTempPermissions(false);
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables.scss";

</style>
